// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-emerson-index-js": () => import("./../../../src/pages/emerson/index.js" /* webpackChunkName: "component---src-pages-emerson-index-js" */),
  "component---src-pages-emerson-mispedidos-js": () => import("./../../../src/pages/emerson/mispedidos.js" /* webpackChunkName: "component---src-pages-emerson-mispedidos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ordenes-js": () => import("./../../../src/pages/ordenes.js" /* webpackChunkName: "component---src-pages-ordenes-js" */),
  "component---src-pages-pedidos-corporativos-js": () => import("./../../../src/pages/pedidos-corporativos.js" /* webpackChunkName: "component---src-pages-pedidos-corporativos-js" */),
  "component---src-pages-productos-ticos-index-js": () => import("./../../../src/pages/productos-ticos/index.js" /* webpackChunkName: "component---src-pages-productos-ticos-index-js" */),
  "component---src-pages-smile-index-js": () => import("./../../../src/pages/smile/index.js" /* webpackChunkName: "component---src-pages-smile-index-js" */),
  "component---src-pages-tiendas-corporativas-js": () => import("./../../../src/pages/tiendas-corporativas.js" /* webpackChunkName: "component---src-pages-tiendas-corporativas-js" */)
}

