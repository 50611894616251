module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"/api"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"La Pulpe Mini Market","short_name":"La Pulpe","start_url":"/","background_color":"#f26821","theme_color":"#f26821","display":"minimal-ui","icon":"src/assets/lapulpe-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"79f8c17e08c585aeae9c86b53e3df6ba"},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-2692116-12","head":false},
    },{
      plugin: require('../plugins/gatsby-facebook-pixel-fmontes/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"972808029781259"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
